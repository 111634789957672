@use '../../variables';
@use './inputs.scss';
@use '../../defaults';
// ====================================================================================================
#login_page {
    width: 100%;
    display: flex;
    flex-direction: row;
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
    .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 50vw;
        &.form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 200px 155px 200px 195px;
            .logo {
                max-width: 76px;
            }
            h1 {
                margin: 25px 0;
                text-transform: capitalize;
                color: variables.$terciary-dark-blue;
            }
            .welcome {
                font-size: variables.$normal-text;
                font-weight: variables.$fw-normal;
                font-family: variables.$font-family;
                margin-bottom: 50px;
                line-height: 2;
            }
            #login_form {
                width: 100%;
                display: flex;
                flex-direction: column;
                .input {
                    @extend %labelUnderInput;
                    max-width: 369px;
                    label {
                        margin-bottom: 27px;
                    }
                    input {
                        border-color: variables.$terciary-dark-blue;
                        border-width: 2px;
                        &::placeholder {
                            color: variables.$grey-400;
                        }
                        &[type='number']::-webkit-outer-spin-button,
                        &[type='number']::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        &[type='number'] {
                            -moz-appearance: textfield;
                        }
                    }
                }
                button {
                    max-width: 219px;
                }
            }
        }
        @include defaults.media-max('tablet') {
            width: 100%;
            max-width: unset;
        }
        &.plant {
            padding: 280px 70px;
            background-color: variables.$primary-green;
            .illustration {
                max-width: 580px;
                height: auto;
                width: 100%;
            }
            @include defaults.media-max('tablet') {
                display: none;
            }
        }
        a {
            font-size: variables.$normal-text;
            font-weight: variables.$fw-semibold;
            color: variables.$terciary-dark-blue;
            text-decoration: none;
            margin-bottom: 50px;
        }
    }
}
