@use '../variables';
@use './react-admin-components/inputs.scss';
// ====================================================================================================
#profile {
    .user-profile {
        max-width: 594px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 90px;
        .column,
        .MuiCardContent-root {
            display: flex;
            flex-direction: column;
            padding: 0;
            width: 100%;
            & > .ra-input-undefined {
                width: 100%;
                .RaFormInput-input-54 {
                    width: 100%;
                }
            }
        }
        .column {
            margin-right: 50px;
            &:last-child {
                margin-right: 0;
            }
        }
        .MuiCardContent-root {
            margin-bottom: 23px;
        }
        .row {
            display: flex;
            flex-direction: row;
            width: 100%;
        }
        .input {
            @extend %labelUnderInput;
        }
    }
}

.modify-password-form {
    width: 100%;
    .MuiCardContent-root {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }
}

.reset-container {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .reset-password-button {
        background-color: transparent;
        padding: 0;
        box-shadow: none;
        border: none;
        transition: 0.3s;
        color: variables.$terciary-dark-blue;
        text-transform: lowercase;
        font-weight: variables.$fw-normal;
        line-height: 1.2;
        font-size: variables.$h3-size;
        cursor: pointer;
        &::first-letter {
            text-transform: capitalize;
        }
        &:hover {
            background-color: transparent;
            box-shadow: none;
            border-bottom: 1px solid variables.$terciary-dark-blue;
        }
    }
}

.modal-container .user-create {
    .input {
        @extend %labelUnderInput;
        width: auto;
        &.select {
            min-width: 236px;
        }
    }
}
