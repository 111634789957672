@use "./variables" as v;
// =====================================================================
@mixin media-min($_key) {
    @media screen and (min-width: map-get(v.$breakpoints, $_key)) {
        @content;
    }
}

@mixin media-max($_key) {
    @media screen and (max-width: map-get(v.$breakpoints, $_key)) {
        @content;
    }
}

@mixin media-between($_key_min, $_key_max) {
    @media screen and (min-width: map-get(v.$breakpoints, $_key_min)) and (max-width: map-get(v.$breakpoints, $_key_max)) {
        @content;
    }
}