@use "../variables";
@use "./react-admin-components/inputs.scss";
@use "../defaults";
// ====================================================================================================
#reset_password_page {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .content {
        width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            margin-bottom: 25px;
            text-align: center;
        }
        .subtitle {
            text-align: center;
            margin-bottom: 50px;
        }
        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .input {
                @extend %labelUnderInput;
                max-width: 369px;
                input {
                    border-color: variables.$terciary-dark-blue;
                    border-width: 2px;
                    &::placeholder {
                        color: variables.$grey-400;
                    }
                    &[type="number"]::-webkit-outer-spin-button,
                    &[type="number"]::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    &[type="number"] {
                        -moz-appearance: textfield;
                    }
                }
                &.error {
                    input {
                        border-color: variables.$danger-red;
                        color: variables.$danger-red;
                        box-shadow: 0 0 10px 5px variables.$danger-red-opacity;
                    }
                }
            }
            .my-button {
                margin-right: 0;
                margin-bottom: 25px;
            }
        }
        a {
            font-size: variables.$normal-text;
            font-weight: variables.$fw-semibold;
            color: variables.$terciary-dark-blue;
            text-decoration: none;
            margin-top: 25px;
        }
    }
}
