@use '../../variables';
@use '../../defaults';

// ====================================================================================================
%filterStyle {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 29px 8px 16px;
    background-color: variables.$light-purple;
    border: 1px solid variables.$light-purple;
    border-radius: variables.$border-radius;
    font-size: variables.$big-text;
    font-weight: variables.$fw-semibold;
    height: variables.$input-height;

    .MuiInputBase-root {
        background-color: variables.$light-purple;
        border: 1px solid variables.$light-purple;
        height: variables.$input-height;

        input {
            font-family: variables.$font-family;
        }
    }

    &:hover {
        .MuiInputBase-root {
            background-color: variables.$terciary-dark-blue;

            input {
                color: variables.$background-white;
            }
        }
    }
}

%filterSelect {
    @extend %filterStyle;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../../../public/assets/chevron-down.svg');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 17px);
    background-position-y: 50%;
    gap: 4px;

    &:hover {
        transition: 0.3s;
        background-image: url('../../../../public/assets/chevron-down-green.svg');
        background-color: variables.$terciary-dark-blue;

        &select,
        &.MuiSelect-select {
            color: variables.$background-white;
            font-weight: variables.$fw-semibold;
        }
    }
}

%pageTitle {
    color: variables.$terciary-dark-blue;
    font-size: variables.$h3-size;
    font-weight: variables.$fw-bold;
    padding: 4px;
    display: flex;
    flex-direction: column;

    .plant-name {
        font-size: variables.$x-small-text;
        color: variables.$grey;
    }
}

.my-list {
    width: 100%;

    & > .MuiToolbar-root {
        display: flex;
        flex-direction: column-reverse;
        padding: 27px 43px 14px 16px;
        z-index: 10;
        background-color: variables.$background-white;

        .my-top-toolbar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 4px;
            width: 100%;
            margin-bottom: 15px;

            .page-title {
                @extend %pageTitle;
            }

            .actions {
                display: flex;
                flex-direction: row;

                & > * {
                    margin-right: 40px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .my-filters {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            border-top: 1px solid variables.$grey;
            min-height: 0;
            padding: 14px 4px 0 4px;
            margin-top: 0;

            .filter-field {
                flex-grow: 1;

                .my-filter {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 48px;
                    margin-top: 0;
                    margin-bottom: 0;
                    // margin-left: 21px;

                    .MuiFormLabel-root {
                        font-family: variables.$font-family;
                        font-size: variables.$big-text;
                        font-weight: variables.$fw-semibold;
                        color: variables.$terciary-dark-blue;
                        position: relative;
                        padding-left: 16px;
                        padding-right: 10px;
                        transform: none;

                        &.MuiInputLabel-shrink {
                            transform: none;

                            &.MuiFormLabel-filled {
                                transform: none;
                                display: none;
                            }
                        }
                    }

                    .MuiInputBase-root {
                        position: absolute;
                        min-width: 100%;
                        width: fit-content;
                        max-width: fit-content;
                        top: 0;
                        left: 0;

                        &.MuiFilledInput-root {
                            border-radius: variables.$border-radius;
                            // border: 0;
                        }

                        &::before,
                        &.MuiFilledInput-underline:after {
                            border-bottom: none;
                        }

                        select,
                        .MuiSelect-select {
                            @extend %filterSelect;
                        }

                        .MuiSelect-icon {
                            display: none;
                        }

                        .MuiFormControl-marginDense {
                            margin: 0;
                        }
                    }

                    &.date-input {
                        @extend %filterStyle;

                        label {
                            text-transform: capitalize;
                            position: absolute;
                            min-width: fit-content;
                            left: 0px;
                            top: 6px;
                            text-align: start;
                            font-family: variables.$font-family;
                            font-weight: variables.$fw-semibold;
                        }

                        .MuiFormLabel-root.MuiInputLabel-shrink.MuiFormLabel-filled {
                            display: unset;
                        }

                        &:hover {
                            input {
                                &::-webkit-calendar-picker-indicator {
                                    // color-scheme: dark;
                                    filter: invert(100%) sepia() saturate(11000%) hue-rotate(90deg);
                                }
                            }
                        }
                    }

                    &:hover {
                        .MuiFormLabel-root,
                        .MuiSelect-root {
                            color: variables.$background-white;
                        }
                    }
                }

                .my-search-field {
                    max-width: variables.$search-input-max-width;
                    width: 100%;
                    height: variables.$input-height;
                    margin: 0;

                    .MuiInputBase-root {
                        border: solid 2px variables.$terciary-dark-blue;
                        display: flex;
                        flex-direction: row-reverse;
                        border-radius: variables.$border-radius;
                        background-color: variables.$light-purple;
                        height: 100%;

                        &::before,
                        &.MuiFilledInput-underline:after {
                            border-bottom: none;
                        }

                        input {
                            padding-left: 0;
                            font-size: variables.$big-text;
                            color: variables.$terciary-dark-blue;
                            font-weight: variables.$fw-semibold;
                            font-family: variables.$font-family;
                            height: 100%;
                            padding: 0;

                            &::placeholder {
                                color: variables.$grey;
                                opacity: 1;
                            }

                            &:focus::placeholder {
                                color: transparent;
                            }
                        }

                        &.Mui-focused {
                            background-color: variables.$background-white;
                        }

                        .MuiInputAdornment-root {
                            margin: 10px;
                            height: 100%;

                            svg {
                                fill: variables.$grey;
                            }
                        }
                    }
                }

                // .RaFilterFormInput-spacer-66,
                .RaFilterFormInput-spacer-76 {
                    display: none;
                }

                .end {
                    margin-left: auto;

                    .MuiInputBase-root {
                        right: 0;
                        left: unset;
                    }
                }

                .date-between-input {
                    display: flex;
                    width: 300px;
                    justify-content: space-between;
                }
            }
        }
    }

    .MuiPaper-root {
        box-shadow: none;
        border-radius: 0px;

        &[class^='RaList-bulkActionsDisplayed-'],
        &[class*=' RaList-bulkActionsDisplayed-'],
        &[class^='jss'],
        &[class*=' jss'] {
            margin-top: 0;
        }

        .MuiToolbar-gutters {
            display: none;
        }

        .my-datagrid {
            .datagrid-header {
                height: 70px;
                background-color: variables.$background-white;

                .MuiTableCell-root {
                    color: variables.$terciary-dark-blue;
                    border: none;
                    font-family: variables.$font-family;
                    font-weight: variables.$fw-bold;

                    .table-title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;

                        span::first-letter {
                            text-transform: capitalize;
                        }
                    }
                }
            }

            .datagrid-body {
                .row {
                    background-color: variables.$light-purple;
                    height: 70px;

                    .MuiTableCell-root {
                        border-bottom: none;
                    }

                    &:nth-child(even) {
                        background-color: variables.$background-white;
                    }

                    &:hover {
                        background-color: variables.$primary-green;
                    }
                }
            }

            .checkbox-cell {
                padding: 0;
                padding-left: 20.33px;

                svg {
                    font-size: 21.34px;
                }
            }

            .action-button-column {
                max-width: 51px;
                width: 51px;
                padding: 0;
            }

            .column {
                width: 100%;
            }

            th:last-of-type {
                width: 109px;
                min-width: 109px;
                max-width: 0;

                &.action-button-column {
                    width: calc(109px + 51px);
                    min-width: calc(109px + 51px);
                }
            }

            &.no-checkbox {
                thead tr th:first-child,
                tbody tr td:first-child {
                    padding-left: 100px;
                }

                thead tr th:last-child,
                tbody tr td:last-child {
                    padding-right: 10vw;
                    width: 60%;
                }
            }
        }

        .my-pagination {
            .MuiToolbar-root {
                display: flex;
                flex-direction: column;
                align-items: center;

                // justify-content: center;
                p.MuiTablePagination-caption {
                    display: none;
                }

                .my-toolbar {
                    display: flex;
                    flex-direction: row;
                    background-color: variables.$background-white;
                    min-height: 0;
                    margin: 28px 0;

                    .nav-button {
                        padding: 0;
                        border-radius: 0;
                        min-width: 0;
                        margin: 0 6px;

                        span {
                            color: variables.$terciary-dark-blue;
                            border-bottom: 1px solid transparent;
                            text-transform: capitalize;
                            font-family: variables.$font-family;
                            font-weight: variables.$fw-normal;
                            font-size: variables.$small-text;
                        }

                        &:disabled {
                            color: variables.$light-grey;
                        }

                        &:hover {
                            background-color: transparent;

                            span {
                                border-color: variables.$terciary-dark-blue;
                            }
                        }
                    }

                    .spacer {
                        margin-right: 5px;
                        margin-left: 5px;
                    }

                    .page-indicator {
                        background-color: variables.$light-grey;
                        color: variables.$terciary-dark-blue;
                        border-radius: 8px;
                        height: 31px;
                        width: 31px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: variables.$fw-semibold;
                        font-family: variables.$font-family;
                        font-size: variables.$small-text;
                        cursor: pointer;
                        margin: 0 6px;

                        &.active {
                            color: variables.$background-white;
                            background-color: variables.$secondary-pink;
                        }
                    }
                }
            }
        }
    }

    .empty-single-entity {
        width: 100%;
        text-align: center;
        padding-top: 20vh;
        text-transform: lowercase;

        &::first-letter {
            text-transform: uppercase;
        }

        .empty-text {
            color: variables.$terciary-dark-blue;
            font-family: variables.$font-family;
            font-size: variables.$big-text;
            font-weight: variables.$fw-normal;
        }
    }
}

.single-entity-page {
    .my-list {
        .MuiToolbar-root {
            flex-direction: row;
            padding: 0;

            @include defaults.media-max('tablet') {
                flex-wrap: wrap-reverse;
            }

            .my-filters.single-line {
                width: auto;
                border: none;
                justify-content: unset;
                padding: 10px 20px 0 20px;
                flex-wrap: nowrap;

                .filter-field {
                    flex-grow: unset;
                }

                & > * {
                    padding-bottom: 10px;
                }

                @include defaults.media-max('tablet') {
                    padding-bottom: 0;
                    width: 100%;
                }

                .material-name {
                    font-size: variables.$h2-size;
                    font-weight: variables.$fw-bold;
                    white-space: nowrap;
                }
            }

            .single-line-actions {
                padding: 0 20px;
                margin-left: auto;
                flex-wrap: wrap;
                flex-grow: 1;

                @include defaults.media-max('tablet') {
                    padding-right: 0;
                    justify-content: flex-start;
                    padding-left: 23px;
                }

                & > * {
                    margin-left: 20px;
                    margin-top: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
