@use "../../variables";
// ====================================================================================================
.my-layout {
  &[class^="RaLayout-root-"],
  &[class*=" RaLayout-root-"] {
    background-color: variables.$background-white;
  }
  .my-sidebar {
    max-width: variables.$sidebar-width;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000;
    background-color: variables.$background-white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
    .menu-header {
      background-color: variables.$primary-green;
      height: 169px;
      display: flex;
      align-items: center;
      justify-content: center;
      .menu-logo {
        max-height: 88px;
        max-width: 55px;
      }
    }
    .MuiDrawer-root {
      height: auto;
      & > .MuiPaper-root {
        position: relative;
        height: auto;
        & > div,
        & > div > div {
          margin: 0;
          height: fit-content;
          max-width: variables.$sidebar-width;
          a.MuiListItem-button {
            height: 70px;
            padding: 15px 23px;
            font-size: variables.$small-text;
            font-weight: variables.$fw-bold;
            font-family: variables.$font-family;
            color: variables.$terciary-dark-blue;
            transition: 0.3s;
            background-color: variables.$background-white;
            &:hover,
            &[class^="RaMenuItemLink-active-"],
            &[class*=" RaMenuItemLink-active-"],
            &.jss49,
            &.jss43 {
              background-color: variables.$secondary-pink;
              color: variables.$background-white;
            }
            .MuiListItemIcon-root {
              min-width: 0;
              margin-right: 4px;
            }
          }
        }
      }
    }
    .logout-container {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 77px 0;
      button {
        background-color: transparent;
        border: none;
        img {
          cursor: pointer;
        }
      }
    }
  }
  #main-content {
    padding: 0;
    margin-left: 175px;
    background-color: variables.$background-white;
    &.RaLayout-content-4 {
      background-color: variables.$background-white;
    }
  }
}
