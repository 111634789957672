@use '../variables';
// ====================================================================================================
%clickable {
    color: variables.$secondary-pink;
    font-size: variables.$h2-size;
    font-weight: variables.$fw-bold;
    font-family: variables.$font-family;
}

.movement-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 80px 58px 100px;
    .locations {
        display: flex;
        flex-direction: column;
        h2 {
            color: variables.$terciary-dark-blue;
        }
        .location-name {
            @extend %clickable;
            display: flex;
            flex-direction: row;
            a {
                color: unset;
                text-decoration: none;
            }
            button.view-pictures {
                @extend %clickable;
                background-color: unset;
                border: none;
                font-size: variables.$big-text;
                cursor: pointer;
            }
            .name {
                margin-right: 40px;
            }
            .archived {
                color: variables.$grey;
            }
        }
        & > * {
            margin-bottom: 10px;
        }
    }
    .details {
        font-size: variables.$h2-size;
        font-weight: variables.$fw-bold;
        color: variables.$terciary-dark-blue;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: end;
        span {
            line-height: 1.3;
        }
    }
}
