@use "./variables";
@use "./defaults";
// ====================================================================================================
body {
    margin: 0;
    font-family: Inter, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 100vw;
    #root {
        max-width: inherit;
        @include defaults.media-max("tablet") {
            display: none;
        }
    }
    #use-desktop {
        display: none;
        @include defaults.media-max("tablet") {
            display: block;
        }
    }
    .my-layout {
        & > div {
            margin-top: 0;
            header.MuiAppBar-root {
                display: none;
            }
            #main-content {
                .MuiPaper-elevation1 {
                    box-shadow: none;
                }
            }
        }
    }
    span,
    div,
    a,
    button {
        font-family: variables.$font-family;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: variables.$font-family;
        font-size: variables.$big-text;
        margin: 0;
        font-weight: variables.$fw-bold;
    }
    h1 {
        font-size: variables.$h1-size;
    }
    h2 {
        font-size: variables.$h2-size;
    }
    h3 {
        font-size: variables.$h3-size;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.MuiPopover-root li {
    min-height: 36px;
}
