// COLORS
$primary-green: #6ae7d0;
$secondary-pink: #ed6ea6;
$background-white: #ffffff;
$terciary-dark-blue: #1a2339;
$light-purple: #f7f6fe;
$light-pink: #fff0f7;
$grey: #7d7d7d;
$grey-400: #7c8db0;
$grey-300: #a1b0cc;
$light-grey: #f0f0f0;
$success-green: #1f9254;
$success-green-opacity: #ebf9f1;
$warning-orange: #cd6200;
$warning-orange-opacity: #fef2e5;
$danger-red: #ae282c;
$danger-red-opacity: #fbe7e8;
$pink-fill-25: #c74b85;
$pink-fill-50: #a22665;
$pink-fill-75: #7e0047;
$pink-fill-100: #5b002b;
$input-error: #f44336;
// FONT WEIGHTS
$fw-bold: 700;
$fw-semibold: 600;
$fw-normal: 400;
// FONT SIZES
$h1-size: 32px;
$h2-size: 20px;
$h3-size: 18px;
$big-text: 16px;
$normal-text: 14px;
$small-text: 12px;
$x-small-text: 10px;
$button-text: 18px;
$dialog-title: 24px;
// FONT FAMILY
$font-family: Inter, sans-serif;
// SIZES
$border-radius: 4px;
$border-radius-lg: 10px;
$input-height: 48px;
$search-input-max-width: 631px;
$icon-size: 24px;
$sidebar-width: 175px;
// BREAKPOINTS
$breakpoints: (
    'tablet': 991px,
    'mobile': 480px
);
