@use "../variables";
@use "./react-admin-components/my-list.scss";
// ====================================================================================================
.my-list .MuiToolbar-root .my-filters .filter-field {
    .range-selector-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 163px;
        justify-content: space-between;
        font-size: variables.$small-text;
        font-weight: variables.$fw-normal;
        margin-left: 21px;
        font-family: variables.$font-family;
        color: variables.$terciary-dark-blue;
        opacity: 1;
        font-family: variables.$font-family;
        select {
            @extend %filterSelect;
            background-position-x: calc(100% - 10px);
            width: 69px;
            height: 48px;
            color: variables.$terciary-dark-blue;
            padding-right: 12px;
            font-family: variables.$font-family;
            &:hover {
                color: variables.$background-white;
            }
        }
    }
}