@use '../../variables';
// ====================================================================================================
%button {
    padding: 8px 12px;
    height: variables.$input-height;
    border: 2px solid variables.$terciary-dark-blue;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-width: 219px;
    &:last-of-type {
        margin-right: 0;
    }
    .MuiButton-label span {
        text-transform: none;
        font-size: variables.$big-text;
        font-weight: variables.$fw-semibold;
        padding-left: 8px;
        font-family: variables.$font-family;
        flex-grow: 1;
    }
    span.MuiButton-label {
        text-transform: none;
    }
    &.inactive {
        pointer-events: none;
        opacity: 0.3;
    }
}

.view-button {
    background-color: transparent;
    max-width: 20px;
    display: flex;
    &:hover {
        background-color: transparent;
    }
}

.MuiButtonBase-root.my-delete-button {
    background-color: transparent;
    padding: 0;
    min-width: 0;
    &.RaDeleteWithConfirmButton-deleteButton-109:hover {
        background-color: transparent;
    }
    svg {
        fill: variables.$terciary-dark-blue;
        font-size: variables.$icon-size;
    }
    &:hover {
        background-color: transparent;
    }
}

.my-add-button.MuiButtonBase-root {
    @extend %button;
    background-color: variables.$terciary-dark-blue;
    .MuiButton-label span {
        color: variables.$background-white;
    }
    &:focus {
        color: transparent;
    }
    &:hover {
        background-color: variables.$terciary-dark-blue;
    }
}

.modify-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .modify-account.MuiButtonBase-root {
        background-color: transparent;
        padding: 0;
        box-shadow: none;
        transition: 0.3s;
        span {
            display: inline-block;
            color: variables.$terciary-dark-blue;
            text-transform: lowercase;
            font-weight: variables.$fw-normal;
            line-height: 1.2;
            font-size: variables.$h3-size;
            border-bottom: 1px solid transparent;
            &::first-letter {
                text-transform: uppercase;
            }
            svg {
                display: none;
            }
        }
        &:hover {
            background-color: transparent;
            box-shadow: none;
            span {
                border-bottom: 1px solid variables.$terciary-dark-blue;
            }
        }
    }
}

.my-button,
.my-button.MuiButtonBase-root {
    @extend %button;
    background-color: variables.$terciary-dark-blue;
    border-radius: variables.$border-radius;
    color: variables.$background-white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: variables.$big-text;
    font-weight: variables.$fw-semibold;
    opacity: 1;
    cursor: pointer;
    transition: 0.3s;
    span::first-letter {
        text-transform: capitalize;
    }
    &.second-style {
        background-color: variables.$light-grey;
        color: variables.$grey;
        border-color: variables.$light-grey;
    }
    &:hover {
        background-color: variables.$light-pink;
        color: variables.$terciary-dark-blue;
        border-color: variables.$light-pink;
    }
    &.button-icon {
        img {
            margin-right: 8px;
        }
        &:hover {
            background-color: variables.$terciary-dark-blue;
            color: variables.$background-white;
            border-color: variables.$terciary-dark-blue;
        }
    }
    &.my-save-button {
        margin-top: 20px;
        margin-left: auto;
        span.MuiButton-label {
            display: inline-block;
            &::first-letter {
                text-transform: uppercase;
            }
        }
        svg {
            display: none;
        }
        &.no-margin {
            margin: 0;
        }
    }
    &.custom-delete-button {
        border: none;
        outline: none;
        padding: 0;
        background-color: variables.$danger-red-opacity;
        color: variables.$danger-red;
        .icon-primary {
            path {
                fill: variables.$danger-red;
            }
        }
        &:hover {
            background-color: variables.$danger-red-opacity;
            color: variables.$warning-orange;
            .icon-primary {
                path {
                    fill: variables.$warning-orange;
                }
            }
        }
    }
}

.icon-button {
    background-color: transparent;
    border: 0;
    display: flex;
    cursor: pointer;
    .icon-primary {
        path {
            fill: variables.$terciary-dark-blue;
        }
    }
    &:hover {
        background-color: transparent;
    }
}

.icon-button-secondary {
    background-color: variables.$danger-red-opacity;
    width: 40px;
    height: 40px;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;

    .icon-primary {
        path {
            fill: variables.$danger-red;
        }
    }
    &:hover {
        .icon-primary {
            path {
                fill: variables.$warning-orange;
            }
        }
    }
}

.my-export-button.MuiButtonBase-root {
    @extend %button;
    gap: 8px;
    background-color: variables.$background-white;
    border-radius: variables.$border-radius;
    &:hover {
        background-color: variables.$background-white;
    }
    .MuiButton-label span {
        color: variables.$terciary-dark-blue;
    }
    &.inactive {
        opacity: 0.3;
        pointer-events: none;
    }
}
