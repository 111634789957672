@use '../../variables';
// ====================================================================================================
.modal-container {
    &.icon .MuiDialog-paper {
        padding-top: 30px;
        padding-bottom: 50px;
    }
    .MuiDialog-paper {
        border-radius: variables.$border-radius-lg;
        width: 855px;
        padding: 76px 96px 63px;
        display: flex;
        flex-direction: column;
        .close-icon {
            width: 34.79px;
            height: 34.79px;
            margin-left: auto;
            cursor: pointer;
        }
        .dialog-title {
            font-weight: variables.$fw-bold;
            font-size: variables.$dialog-title;
            color: variables.$terciary-dark-blue;
            text-align: center;
            &.left {
                text-align: start;
            }
        }
        .dialog-subtitle {
            font-size: variables.$normal-text;
            color: variables.$terciary-dark-blue;
            text-align: center;
            padding: 30px 0;
            &::first-letter {
                text-transform: capitalize;
            }
        }
        .buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-evenly;
            margin: 0;
            padding: 0;
            min-height: 0;
            background: transparent;
        }
    }
    &.images-modal {
        .MuiDialog-paper {
            background-color: transparent;
            box-shadow: none;
            padding: 0;
            max-width: 860px;
            border-radius: 0;
            .close-icon {
                position: absolute;
                top: 13px;
                right: 13px;
            }
            .images {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .movement-image {
                    max-width: 324px;
                    max-height: 666px;
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                    border-radius: variables.$border-radius;
                    &:first-of-type {
                        margin-right: 120px;
                    }
                }
            }
        }
    }
    &.uploads-modal {
        .MuiDialog-paper {
            max-width: 80%;
            min-height: 50vh;
            .title {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
            }
            .movement-uploads-slider {
                .slick-slide {
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    flex-wrap: wrap;
                }
                .slick-slide {
                    height: 500px;
                }
                .slick-prev,
                .slick-next {
                    background: variables.$secondary-pink;
                    width: 52px;
                    height: 52px;
                    padding: 0px 0px 0px 5px;
                    outline: none;
                    color: transparent;
                    font-size: 0px;
                    line-height: 0;
                    position: absolute;
                    top: 50%;
                    display: block;
                    visibility: visible;
                    cursor: pointer;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                }
                .slick-prev {
                    left: 0px;
                }
                .slick-next {
                    left: 100%;
                    transform: translate(-50%, -50%) scaleX(-1);
                }
                .slick-prev::before,
                .slick-next::before {
                    content: ' ';
                    opacity: 0.75;
                    width: 14px;
                    height: 14px;
                    margin: 0px auto;
                    border-left: 2px solid rgb(255, 255, 255);
                    border-bottom: 2px solid rgb(255, 255, 255);
                    display: block;
                    transform: rotate(45deg);
                }
            }

            .image-container {
                position: relative;
                .movement-image {
                    max-height: 500px;
                    object-fit: contain;
                    border-radius: variables.$border-radius;
                }
                .small-image {
                    height: 500px;
                }
                .image-delete-button {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
            }

            .pdf-container .pdf-buttons {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }
    }
    .create-form {
        .MuiPaper-root {
            box-shadow: none;
            .MuiCardContent-root {
                padding: 0;
                .MuiFormControl-marginDense {
                    margin: 0;
                }
            }
        }
    }
    .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 1px;
        .input {
            width: auto;
            min-width: 219px;
            &.select {
                min-width: 256px;
            }
            &.label-under-input {
                label {
                    margin-bottom: 20px;
                }
            }
            &.x-small {
                max-width: 80px;
                min-width: 0;
                label {
                    display: none;
                }
                &.label-under-input label {
                    display: inherit;
                    margin-bottom: 0;
                    span {
                        margin-bottom: 0;
                        padding: 0;
                    }
                }
                input {
                    min-width: 0;
                    width: auto;
                    &[type='number']::-webkit-inner-spin-button,
                    &[type='number']::-webkit-outer-spin-button {
                        opacity: 1;
                    }
                }
            }
            &.no-margin-bottom {
                label,
                label span {
                    margin-bottom: 0;
                }
            }
        }
        &.plant-row {
            margin-top: 20px;
            .input {
                width: 100%;
            }
        }
    }
    &.movements-modal {
        .MuiDialog-paper {
            padding: 45px 0 0;
            .close-icon {
                position: absolute;
                top: 13px;
                right: 13px;
            }
            .movement-row {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 34px 55px 44px 55px;
                text-decoration: none;
                .title {
                    font-size: variables.$h2-size;
                    font-weight: variables.$fw-bold;
                    color: variables.$terciary-dark-blue;
                    &.date {
                        font-size: 22px;
                        display: inline-block;
                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }
                }
                .row {
                    display: flex;
                    flex-direction: row;
                    margin-top: 44px;
                    .column {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .illustration {
                            height: 56px;
                        }
                        &.location {
                            width: 50%;
                            .location-name {
                                color: variables.$secondary-pink;
                                font-size: variables.$h2-size;
                                font-weight: variables.$fw-bold;
                                text-decoration: none;
                                margin-top: 20px;
                            }
                        }
                        &:last-of-type {
                            text-align: end;
                        }
                    }
                }
                &:hover {
                    background-color: variables.$primary-green;
                }
            }
        }
    }
    .MuiCard-root.margin-top {
        margin-top: 1em;
        max-height: 300px;
        overflow-y: scroll;
        .MuiTable-root.my-datagrid {
            thead {
                position: sticky;
                top: 0;
                background-color: variables.$background-white;
                z-index: 1000;
            }
            .row {
                display: table-row;
            }
        }
    }
}

.content-text {
    display: block;
    font-family: variables.$font-family;
    font-size: variables.$normal-text;
    color: variables.$grey;
    text-transform: lowercase;
    text-align: center;
    &::first-letter {
        text-transform: uppercase;
    }
}
