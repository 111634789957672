@use "../../variables";
// ====================================================================================================
.text-field-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: 32px;
    width: 32px;
    object-fit: cover;
    margin-right: 8px;
    border-radius: 8px;
  }
}

.MuiTypography-body2.text-field,
.text-field {
  display: block;
  color: variables.$terciary-dark-blue;
  font-style: normal;
  font-family: Inter;
  font-size: variables.$big-text;
  font-weight: variables.$fw-normal;
  font-family: variables.$font-family;
  line-height: 19px;
  word-break: break-word;
  &.pink-text-field {
    color: variables.$secondary-pink;
    font-weight: variables.$fw-bold;
  }
  &.terciary-text-field {
    font-weight: variables.$fw-bold;
  }
  &.bold {
    font-weight: variables.$fw-bold;
  }
  &.filling-text-field,
  &.dm-state-text-field {
    font-weight: variables.$fw-bold;
    line-height: 31px;
    border-radius: 22px;
    width: 100%;
    max-width: 155px;
    font-size: variables.$small-text;
    font-weight: variables.$fw-bold;
    text-align: center;
    &.UNUSABLE,
    &.NA {
      background-color: variables.$grey;
      color: variables.$background-white;
    }
    &.TO_PROCESS {
      background-color: variables.$success-green-opacity;
      color: variables.$success-green;
    }
    &.MAINTENANCE {
      background-color: variables.$warning-orange-opacity;
      color: variables.$warning-orange;
    }
    &.DISCARDED {
      background-color: variables.$danger-red-opacity;
      color: variables.$danger-red;
    }
    &.REPAIRED {
      background-color: variables.$light-grey;
      color: variables.$grey;
    }
    &.EMPTY {
      background-color: variables.$success-green;
      color: variables.$background-white;
    }
    &.ONE_QUARTER {
      background-color: variables.$pink-fill-25;
      color: variables.$background-white;
    }
    &.HALF {
      background-color: variables.$pink-fill-50;
      color: variables.$background-white;
    }
    &.THREE_QUARTER {
      background-color: variables.$pink-fill-75;
      color: variables.$background-white;
    }
    &.FULL {
      background-color: variables.$pink-fill-100;
      color: variables.$background-white;
    }
  }
  &.lettering {
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.lowercase,
  &.lowercase::first-letter {
    text-transform: lowercase;
  }
}
