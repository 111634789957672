@use '../../variables';
// ====================================================================================================
%inputStyle {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    width: 100%;
    .MuiFormLabel-filled {
        display: none;
    }
    .MuiInputBase-root {
        height: variables.$input-height;
        max-height: variables.$input-height;
        &::before,
        &::after {
            border-bottom: 0;
        }
    }
    .MuiFilledInput-underline:hover::before,
    .MuiFilledInput-underline:hover::after {
        border-bottom: 0;
    }
    .MuiFormHelperText-root {
        order: 2;
        margin-bottom: 5px;
    }
    input {
        min-width: 180px;
        width: 100%;
        height: variables.$input-height;
        max-height: calc(variables.$input-height - 2px);
        background: variables.$background-white;
        border: 1px solid variables.$grey-300;
        border-radius: variables.$border-radius;
        padding: 0 12px;
        transition: 0.3s;
        color: variables.$grey-400;
        font-family: variables.$font-family;
        font-size: variables.$h3-size;
        font-weight: variables.$fw-semibold;
        &:focus {
            border-color: variables.$terciary-dark-blue;
        }
        &:disabled {
            background-color: variables.$light-grey;
            cursor: not-allowed;
        }
    }
    label {
        &.MuiInputLabel-filled.MuiInputLabel-marginDense {
            transform: none;
        }
        top: 50%;
        margin-top: -10px;
        padding-left: 12px;
        &.Mui-focused {
            display: none;
        }
        span {
            color: variables.$grey-400;
            font-size: variables.$big-text;
            font-weight: variables.$fw-semibold;
            font-family: variables.$font-family;
            transform: none;
        }
        &::first-letter {
            text-transform: capitalize;
        }
    }
    &.input-password {
        max-width: calc(100% - 2px);
        .MuiInputBase-root {
            border: 1px solid variables.$grey-300;
            border-radius: variables.$border-radius;
            background-color: variables.$background-white;
            input {
                border: none;
            }
            .MuiInputAdornment-root {
                button {
                    transition: none;
                    svg {
                        color: variables.$grey-300;
                    }
                }
            }
        }
    }
    &.select {
        .MuiSelect-root {
            display: flex;
            align-items: center;
            min-width: 180px;
            width: 100%;
            height: variables.$input-height;
            background: variables.$background-white;
            border: 1px solid variables.$grey-300;
            border-radius: variables.$border-radius;
            padding: 0 12px;
            transition: 0.3s;
            color: variables.$grey-400;
            font-family: variables.$font-family;
            font-size: variables.$h3-size;
            font-weight: variables.$fw-semibold;
        }
    }
    &.auto-complete {
        .MuiInputBase-root {
            border: 1px solid variables.$grey-300;
            border-radius: variables.$border-radius;
            background-color: variables.$background-white;
            input {
                border: none;
            }
        }
    }
    .MuiInputBase-multiline {
        height: auto;
        max-height: fit-content;
        background: variables.$background-white;
        border: 1px solid variables.$grey-300;
        border-radius: variables.$border-radius;
        padding: 10px 12px;
        transition: 0.3s;
        color: variables.$grey-400;
        font-family: variables.$font-family;
        font-size: variables.$h3-size;
        font-weight: variables.$fw-semibold;
        &:focus {
            border-color: variables.$terciary-dark-blue;
        }
        &.MuiFilledInput-root {
            &:hover {
                background-color: variables.$background-white;
            }
            &.Mui-focused {
                background-color: variables.$background-white;
            }
        }
    }
    &.max-272 {
        max-width: 272px;
    }
    .Mui-error {
        border-color: variables.$input-error;

        input,
        .MuiInputBase-input,
        .MuiSelect-root,
        &.MuiInputBase-multiline {
            border-color: variables.$input-error;
        }
    }
}

%labelUnderInput {
    @extend %inputStyle;
    .MuiFormLabel-filled {
        display: unset;
    }
    label,
    label span {
        order: 1;
        position: relative;
        font-size: variables.$normal-text;
        color: variables.$grey-400;
        font-weight: variables.$fw-normal;
        transform: none;
        padding-top: 7px;
        padding-left: 4px;
        top: unset;
        margin-top: unset;
        &.Mui-focused {
            display: unset;
        }
        &.Mui-error,
        &.Mui-error span {
            color: variables.$input-error;
        }
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
        transform: none;
    }
    &.disabled-input {
        max-width: 100%;
        width: 100%;
        input,
        .MuiSelect-root {
            width: auto;
            background-color: variables.$light-purple;
            cursor: not-allowed;
        }
        .MuiInputBase-root input,
        .MuiSelect-root {
            width: 100%;
        }
    }
}

.input {
    @extend %inputStyle;
    &.label-under-input {
        @extend %labelUnderInput;
    }
    &.no-arrows {
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }
    }
    &.no-margin-bottom {
        label,
        label span {
            margin-bottom: 0;
        }
    }
}

.auto-complete-input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    .icon {
        position: absolute;
        top: 12px;
        left: 15px;
        z-index: 1;
        &.border {
            border-color: variables.$terciary-dark-blue;
        }
    }
    .input {
        input {
            padding-left: 50px;
        }
    }
}

.MuiFormControl-root {
    div[class^='RaLabeled-value'] {
        display: flex;
        flex-direction: column;
    }
    // div[class^='RaFileInput-dropZone-'] {
    //     background-color: variables.$background-white;
    //     border: 1px solid variables.$grey-300;
    //     border-radius: variables.$border-radius;
    //     padding: 20px;
    //     p {
    //         font-size: variables.$normal-text;
    //         color: variables.$grey-400;
    //         font-weight: variables.$fw-normal;
    //     }
    // }
    .MuiFormHelperText-root {
        order: 2;
    }
    .previews {
        order: 1;
    }
}

.my-image-input {
    div[class^='RaFileInput-dropZone-'],
    div[class*=' RaFileInput-dropZone-'] {
        background-color: variables.$background-white;
        border: 1px solid variables.$grey;
        border-radius: variables.$border-radius-lg;
        padding: 41px;
    }
    &.MuiFormControl-marginDense {
        margin: 0;
        div[class^='RaLabeled-value-'],
        div[class*=' RaLabeled-value-'] {
            padding: 0;
            .previews {
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
                max-height: 136px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                div[class^='RaFileInput-removeButton-'],
                div[class*=' RaFileInput-removeButton-'] {
                    pointer-events: all;
                    img {
                        max-height: 120px;
                        margin: 0;
                    }
                }
            }
        }
    }
}
