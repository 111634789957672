@use '../variables';
@use './react-admin-components/inputs.scss';

// ====================================================================================================
#single-dm-page {
    .loading-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 100px 0;
    }
    .dm-content {
        max-width: 972px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 100px;

        h3 {
            color: variables.$terciary-dark-blue;
            margin-bottom: 20px;
            text-transform: lowercase;
            &::first-letter {
                text-transform: uppercase;
            }
        }

        .agents {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            gap: 25px;
            margin-bottom: 37px;

            .col {
                width: 100%;
            }
        }

        .input {
            @extend %labelUnderInput;

            input {
                width: auto;
                height: 48px;
                max-height: 46px;
                background: #ffffff;
                border: 1px solid #a1b0cc;
                border-radius: 4px;
                transition: 0.3s;
                color: #7c8db0;
                font-family: Inter, sans-serif;
                font-size: 18px;
                font-weight: 600;
            }

            label {
                margin-bottom: 20px;
            }
        }

        .show-photo {
            display: flex;
            justify-content: end;
            margin-bottom: 60px;
        }

        .comment {
            margin-bottom: 64px;

            textarea {
                width: calc(100% - 42px);
                height: 202px;
                background: variables.$background-white;
                border: 1px solid variables.$grey-300;
                border-radius: variables.$border-radius;
                padding: 12px 21px;
                line-height: 1.2;
                transition: 0.3s;
                color: variables.$grey-400;
                font-family: variables.$font-family;
                font-size: variables.$h3-size;
                font-weight: variables.$fw-semibold;
                resize: none;
                &:disabled {
                    background-color: variables.$light-grey;
                    cursor: not-allowed;
                }
            }
        }

        .send-button {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 40px;
        }
    }
}
