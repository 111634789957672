@use "../variables";
@use "./react-admin-components/my-list.scss";
// ====================================================================================================
.page-header {
  background-image: url("../../../public/assets/Centrale_nucléaire_du_Blayais_2.jpeg");
  height: 138px;
  max-height: 138px;
  background-size: cover;
  background-position-y: 35%;
  padding-top: 31px;
  padding-left: 25px;
  padding-bottom: 0;
  @extend %pageTitle;
  .breadcrumb {
    font-size: variables.$h2-size;
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      color: variables.$grey;
      text-decoration: none;
    }
    .crumb {
      display: flex;
      flex-direction: row;
      align-items: center;
      &:last-child {
        a {
          color: variables.$terciary-dark-blue;
        }
        img {
          display: none;
        }
      }
    }
  }
  &.no-background,
  &.user-page-header {
    background-image: none;
    height: 97px;
  }
  &.user-page-header {
    padding-right: 46px;
    padding-left: 17px;
    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px 31px 15px 7px;
      border-bottom: 1px solid variables.$terciary-dark-blue;
      .col {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
